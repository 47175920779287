<template>
  <div style="display: contents">
    <v-container fluid>
      <v-card :loading="loading">
        <v-toolbar short flat>
          <v-toolbar-title> Database Design </v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>

          <v-spacer></v-spacer>
          <v-divider class="mx-4" inset vertical></v-divider>

          <ActionButton
            v-for="action in actions"
            :key="action.name"
            :icon="action.icon"
            :tooltip="action.text"
            :disabled="action.name == 'delete' && selected.length == 0"
            @action-click="onActionClick(action.name)"
          />
        </v-toolbar>
        <v-divider />

        <v-data-table
          class="crud-table lnp-table"
          show-select
          item-key="ATTR_ID"
          v-model="selected"
          :headers="headers"
          :items="items"
          :options="{ itemsPerPage: -1 }"
          @click:row="onRowClick"
          :height="tableHeight"
          v-resize="onResize"
          fixed-header
          hide-default-footer
          dense
        >
          <template v-slot:[`item.ASSET_RESOURCE`]="{ item }">
            <a style="text-decoration: none;" target="_blank" :href="item.ASSET_RESOURCE">{{
              item.ASSET_RESOURCE
            }}</a>
          </template>
          <template v-slot:[`item.MANDATORY`]="{ item }">
            <div v-html="item.MANDATORY ? '&#10004;' : ''" />
          </template>
        </v-data-table>
      </v-card>
    </v-container>
    <AttrSetupForm
      :campaignId="campaignId"
      v-model="form.active"
      :attrId="form.attrId"
      @update="onUpdate"
    />
    <AttrReuseForm :campaignId="campaignId" v-model="form2" @update="onUpdate($event, 'reuse')" />
  </div>
</template>

<script>
import AttrReuseForm from "@/components/lnp/Form/AttrReuseForm";
import AttrSetupForm from "@/components/lnp/Form/AttrSetupForm";
import ActionButton from "@/components/util/ActionButton";

export default {
  components: {
    AttrSetupForm,
    AttrReuseForm,
    ActionButton
  },
  props: ["campaignId"],
  data() {
    return {
      tableHeight: 600,
      form: {
        active: false,
        attrId: undefined
      },
      form2: false,
      selected: [],
      loading: false,
      actions: [
        { name: "delete", text: "Delete", icon: "mdi-delete-forever" },
        { name: "useDBAttr", text: "Use Database Attribute", icon: "mdi-database-arrow-down" },
        { name: "new", text: "Add Attribute", icon: "mdi-plus" }
      ],
      items: [],
      headers: [
        {
          text: "ID",
          value: "ATTR_ID",
          width: "150px"
        },
        {
          text: "Attribute Name",
          value: "ATTR_NAME"
        },
        {
          text: "Attribute Label",
          value: "ATTR_LABEL"
        },
        {
          text: "Type",
          value: "ATTR_TYPE",
          width: "50px"
        },
        {
          text: "Value",
          value: "OBJECT_TYPE",
          width: "50px"
        },
        {
          text: "Last Updated",
          value: "MOD_DATE",
          width: "100px"
        },
        {
          text: "Mandatory",
          value: "MANDATORY",
          align: "center",
          width: "50px"
        }
      ]
    };
  },
  watch: {
    campaignId: {
      immediate: true,
      handler(v) {
        if (v) this.fetchList();
      }
    }
  },
  methods: {
    onResize() {
      this.tableHeight = window.innerHeight - 241;
    },
    onUpdate(attrId, type = "setup") {
      if (type == "setup") {
        if (this.form.attrId != attrId) this.form.attrId = attrId;
      } else {
        // close reuse form after saved
        this.form2 = false;
      }
      this.fetchList();
    },
    onActionClick(e) {
      switch (e) {
        case "new":
          this.form.active = true;
          this.form.attrId = undefined;
          break;
        case "useDBAttr":
          this.form2 = true;
          break;
        case "delete":
          this.delete();
          break;
      }
    },
    delete() {
      let idsToDelete = this.selected.map((e) => {
        return e["ATTR_ID"];
      });

      this.$root.$confirm("Delete", "Are you sure?", { color: "red" }).then((confirm) => {
        if (confirm) {
          this.$axios
            .delete("/lnp/db/design/deleteAttr", {
              params: {
                ids: idsToDelete,
                campaignId: this.campaignId
              }
            })
            .then(() => {
              this.fetchList();
              this.$store.commit("sendAlert", {
                msg: "Attribute deleted",
                color: "success"
              });
            });
        }
      });
    },
    fetchList() {
      this.selected = [];
      this.loading = true;
      this.$axios
        .get("/lnp/db/design/list", {
          params: {
            campaignId: this.campaignId
          }
        })
        .then((res) => {
          this.items = res.data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    onRowClick(e) {
      this.form.active = true;
      this.form.attrId = e.ATTR_ID.split("#")[0];
    }
  }
};
</script>
