<template>
  <v-card class="pa-2" outlined>
    <v-label>
      Attribute(s) for dedupe (against existing LNDB records)
    </v-label>
    <v-card-text>
      <v-row>
        <v-col cols="6" v-for="(obj, type) in options" :key="type">
          <div class="overline">{{ type }}</div>
          <v-checkbox
            v-for="(option, index) in obj"
            :key="index"
            hide-details
            style="margin-top: 0;"
            :label="option.text"
            :value="option.value"
            v-model="localValue"
            @change="onChange"
          ></v-checkbox>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: ["type", "value"],
  computed: {
    options() {
      let opt = this.dedupeOption;
      if (this.type == "internal") {
        opt = { Company: [this.dedupeOption.Company[0]] };
      }
      return opt;
    }
  },
  data() {
    return {
      localValue: this.value ?? [],
      dedupeOption: {
        Company: [
          { text: "Company Name + Country", value: "1000001013,1000001014" },
          { text: "Company Name + Phone", value: "1000001013,1000001019" },
          { text: "Website", value: "1000001021" }
        ],
        Contact: [
          {
            text: "Company Name + First Name + Last Name",
            value: "1000001013,1000001004,1000001005"
          },
          { text: "Email Address", value: "1000001024" }
        ]
      }
    };
  },
  watch: {
    value: {
      handler(v) {
        this.localValue = v;
      }
    }
  },
  methods: {
    onChange() {
      this.$emit("input", this.localValue);
    }
  }
};
</script>
