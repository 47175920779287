<template>
  <div style="display: contents">
    <v-container fluid>
      <v-card :loading="loading">
        <v-toolbar short flat>
          <v-toolbar-title> Data Upload </v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>

          <v-spacer></v-spacer>
          <v-divider class="mx-4" inset vertical></v-divider>

          <ActionButton
            v-for="action in actions"
            :key="action.name"
            :icon="action.icon"
            :tooltip="action.text"
            :disabled="action.name == 'delete' && selected.length == 0"
            @action-click="onActionClick(action.name)"
          />
        </v-toolbar>
        <v-divider />

        <v-data-table
          class="crud-table lnp-table"
          :options="{ itemsPerPage: -1 }"
          show-select
          item-key="LNP_UPLOAD_ID"
          v-model="selected"
          :headers="headers"
          :items="items"
          @click:row="onRowClick"
          hide-default-footer
          dense
        >
        </v-data-table>
      </v-card>
    </v-container>
    <ExternalUploadForm
      :uploadId="clicked"
      :campaignId="campaignId"
      v-model="formE.active"
      @update="onUpdate('external')"
    />
    <InternalUploadForm
      :uploadId="clicked"
      :campaignId="campaignId"
      v-model="formI.active"
      @update="onUpdate('internal')"
      :dbItems="dbItems"
    />
  </div>
</template>

<script>
import ExternalUploadForm from "@/components/lnp/DBManagement/ExternalUploadForm";
import InternalUploadForm from "@/components/lnp/DBManagement/InternalUploadForm";
import ActionButton from "@/components/util/ActionButton";

export default {
  components: {
    ExternalUploadForm,
    InternalUploadForm,
    ActionButton
  },
  props: ["campaignId"],
  data() {
    return {
      clicked: undefined,
      formI: {
        loading: false,
        active: false
      },
      formE: {
        loading: false,
        active: false
      },
      selected: [],
      loading: false,
      actions: [
        { name: "delete", text: "Delete", icon: "mdi-delete-forever" },
        { name: "addExternal", text: "Add External", icon: "mdi-file-import" },
        { name: "addInternal", text: "Add Internal", icon: "mdi-database-import" }
      ],
      items: [],
      headers: [
        {
          text: "Name",
          value: "LNP_UPLOAD_NAME"
        },
        {
          text: "Company Added",
          value: "COMPANY_COUNT",
          width: "50px"
        },
        {
          text: "Contact Added",
          value: "CONTACT_COUNT",
          width: "50px"
        },
        {
          text: "Source",
          value: "SOURCE",
          width: "50px"
        },
        {
          text: "Created Date",
          value: "ADD_DATE",
          width: "150px"
        },
        {
          text: "Created By",
          value: "USERNAME",
          width: "150px"
        },
        {
          text: "Status",
          value: "STATUS",
          width: "100px"
        }
      ],
      dbItems: []
    };
  },
  watch: {
    campaignId: {
      immediate: true,
      handler(v) {
        if (v) {
          this.fetchList();
          this.fetchDBList(); // for internal form
        }
      }
    }
  },
  methods: {
    onUpdate(type) {
      if (type == "external") {
        this.formE.active = false;
      }
      this.fetchList();
    },
    onActionClick(e) {
      switch (e) {
        case "addInternal":
          this.formI.active = true;
          this.clicked = undefined;
          break;
        case "addExternal":
          this.formE.active = true;
          this.clicked = undefined;
          break;
        case "delete":
          this.delete();
          break;
      }
    },
    delete() {
      let idsToDelete = this.selected.map((e) => {
        return e["LNP_UPLOAD_ID"];
      });

      this.$root.$confirm("Delete", "Are you sure?", { color: "red" }).then((confirm) => {
        if (confirm) {
          this.$axios
            .delete("/lnp/db/upload/deleteUpload", {
              params: {
                ids: idsToDelete,
                campaignId: this.campaignId
              }
            })
            .then((res) => {
              this.fetchList();
              this.$store.commit("sendAlert", {
                msg: res.data.msg,
                color: "success"
              });
            });
        }
      });
    },
    fetchDBList() {
      this.$axios.get("/lnp/db/upload/getDBList").then((res) => {
        this.dbItems = res.data;
      });
    },
    fetchList() {
      this.selected = [];
      this.loading = true;
      this.$axios
        .get("/lnp/db/upload/list", {
          params: {
            campaignId: this.campaignId
          }
        })
        .then((res) => {
          this.items = res.data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    onRowClick(e) {
      this.clicked = e.LNP_UPLOAD_ID;
      if (e.SOURCE == "EXTERNAL") {
        this.formE.active = true;
      } else {
        this.formI.active = true;
      }
    }
  }
};
</script>
