<template>
  <div style="display:content">
    <v-dialog
      scrollable
      :value="value"
      @keydown.esc="closeForm"
      @input="closeForm"
      max-width="850px"
      persistent
    >
      <v-card :loading="loading">
        <v-toolbar short flat>
          <v-btn icon @click="closeForm">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Add External</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-form v-if="!uploadId" ref="form" v-model="valid" lazy-validation class="crud-form">
            <v-container fluid>
              <v-row>
                <template v-if="step == 1">
                  <v-col cols="12">
                    <v-text-field
                      v-model="values.FILE_NAME"
                      hide-details="auto"
                      dense
                      label="Name"
                      :rules="[(v) => !!v || 'Required']"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-file-input
                      v-model="file"
                      hide-details="auto"
                      :rules="[
                        (value) =>
                          !value || value.size < 10000000 || 'File size should be less than 10 MB'
                      ]"
                      accept="text/*,.zip,.rar,.7zip"
                      label="File"
                    ></v-file-input>
                  </v-col>
                  <!-- <v-col cols="12">
                    <v-select
                      v-model="values.ENCODING"
                      hide-details="auto"
                      dense
                      :menu-props="{ maxHeight: '400' }"
                      :items="encodingOptions"
                      label="Database"
                      :rules="[(v) => !!v || 'Required']"
                    ></v-select>
                  </v-col> -->
                </template>
                <template v-if="step == 2">
                  <v-col cols="12">
                    <AttrHeaderTable
                      :campaignId="campaignId"
                      v-model="values.headers"
                      :items="csvData"
                    />
                  </v-col>
                  <v-col cols="12">
                    <DedupeControl type="external" v-model="values.DedupOptions" />
                  </v-col>
                  <!-- <v-col cols="12">
                    <v-radio-group v-model="values.importType" row hide-details>
                      <v-radio label="Immediate Import" value="IMMEDIATE"></v-radio>
                      <v-radio label="Schedule Import (+8:00)" value="SCHEDULE"></v-radio>
                       <div>
                        <InputControl
                          :bind="{ outlined: true }"
                          label="Scheduled time"
                          v-model="values.scheduleDate"
                          attrType="D"
                          control="DATETIME"
                          :disabled="values.importType == 'IMMEDIATE'"
                        />
                      </div>
                    </v-radio-group>
                  </v-col> -->
                </template>
              </v-row>
            </v-container>
          </v-form>
          <pre style="font-size: 8pt;" v-else>
            {{ exportStatus }}
          </pre>
        </v-card-text>
        <v-card-actions v-if="!this.uploadId">
          <v-spacer />
          <v-btn plain :loading="saving" color="primary" @click="onFormSave">{{
            actionBtnLbl
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import AttrHeaderTable from "@/components/database/AttrHeaderTable";
import DedupeControl from "@/components/lnp/DBManagement/DedupeControl";

export default {
  components: {
    AttrHeaderTable,
    DedupeControl
  },
  props: ["campaignId", "value", "uploadId"],
  computed: {
    actionBtnLbl() {
      return this.step == 1 ? "Next" : "Import";
    }
  },
  data() {
    return {
      saving: false,
      file: undefined,
      step: 1,
      exportStatus: "",
      valid: false,
      loading: false,
      csvData: [],
      values: {},
      encodingOptions: [
        "ISO-8859-1",
        "Big5",
        "GB2312",
        "Shift-JIS",
        "EUC-JP",
        "ISO-2022-JP",
        "EUC-KR",
        "ISO-2022-KR",
        "UTF-8",
        "GBK"
      ]
    };
  },
  watch: {
    value: {
      immediate: true,
      handler(v) {
        if (v) this.load();
      }
    }
  },
  methods: {
    onFormSave() {
      if (this.step == 1) {
        this.processCSV();
      } else {
        this.doImport();
      }
    },
    processCSV() {
      let formData = new FormData();
      formData.append("file", this.file);
      formData.append("FILE_NAME", this.values.FILE_NAME);
      formData.append("campaignId", this.campaignId);

      this.saving = true;
      this.$axios
        .post("/lnp/db/upload/processCSV", formData, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        })
        .then((res) => {
          this.values.headers = res.data.headers;
          this.csvData = res.data.items;
          this.values.filePath = res.data.filePath;
          this.step = 2;
        })
        .finally(() => {
          this.saving = false;
        });
    },
    doImport() {
      this.saving = true;
      this.$axios({
        method: "post",
        url: "/lnp/db/upload/do_external_import",
        data: { ...this.values, campaignId: this.campaignId }
      })
        .then(() => {
          this.$emit("update", true);
          this.$store.commit("sendAlert", {
            msg: "External data import queued for import",
            color: "success"
          });
        })
        .finally(() => {
          this.saving = false;
        });
    },
    closeForm() {
      this.$emit("input", false);
    },
    load() {
      this.step = 1;
      this.file = undefined;
      this.values = {
        ENCODING: "UTF-8",
        importType: "IMMEDIATE"
      };
      if (this.uploadId) {
        this.loading = true;
        this.$axios
          .get("/lnp/db/upload/getImportStatus", {
            params: {
              id: this.uploadId
            }
          })
          .then((res) => {
            this.exportStatus = res.data.content ? res.data.content : res.data.error;
          })
          .finally(() => {
            this.loading = false;
          });
      }
    }
  }
};
</script>
