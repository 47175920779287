<template>
  <div style="display:content">
    <v-dialog
      scrollable
      :value="value"
      @keydown.esc="closeForm"
      @input="closeForm"
      max-width="1000px"
      persistent
    >
      <v-card :loading="loading">
        <v-toolbar short flat>
          <v-btn icon @click="closeForm">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Add Internal</v-toolbar-title>
        </v-toolbar>
        <v-card-text class="contentContainer">
          <v-form ref="form" v-model="valid">
            <v-container fluid>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model="values.Query_name"
                    hide-details="auto"
                    dense
                    label="Name"
                    :rules="[(v) => !!v || 'Required']"
                    :disabled="readOnly"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-select
                    v-model="values.DB_CLASS_ID"
                    hide-details="auto"
                    dense
                    @change="onDBSelect"
                    :menu-props="{ maxHeight: '400' }"
                    :items="dbItems"
                    label="Database"
                    :rules="[(v) => !!v || 'Required']"
                    :disabled="!!values.DB_CLASS_ID"
                  ></v-select>
                </v-col>
                <v-col cols="12">
                  <v-card class="pa-2" outlined>
                    <v-label>
                      Query
                    </v-label>
                    <v-card-text>
                      <Query ref="query" v-show="attrs" :readOnly="readOnly" />
                      <p v-if="!attrs" class="caption">
                        {{ !loadingQuery ? "Please select a database." : "Loading..." }}
                      </p>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col cols="12">
                  <DedupeControl type="internal" v-model="values.DedupOptions" />
                </v-col>
                <v-col cols="12" v-show="clicked.count">
                  <v-card outlined>
                    <v-card-text v-if="Object.keys(count).length === 0">
                      <p class="caption">Getting count ...</p>
                      <v-progress-linear indeterminate color="yellow darken-2" />
                    </v-card-text>
                    <v-simple-table v-else dense>
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th class="text-left"></th>
                            <th class="text-left">
                              Company
                            </th>
                            <th class="text-left">
                              Contact
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Total Matched</td>
                            <td>{{ count.compcount }}</td>
                            <td>{{ count.contcount }}</td>
                          </tr>
                          <tr>
                            <td>Duplicate Found</td>
                            <td>{{ count.dupcompany }}</td>
                            <td>{{ count.dupcontact }}</td>
                          </tr>
                          <tr class="font-weight-black">
                            <td>To Be Imported</td>
                            <td>{{ companyToImport }}</td>
                            <td class="font-italic font-weight-light">-</td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-card>
                </v-col>
                <v-col cols="12" v-show="clicked.import">
                  <v-card outlined>
                    <v-card-text>
                      <template v-if="Object.keys(importCount).length === 0">
                        <p class="caption">Importing ...</p>
                        <v-progress-linear indeterminate color="yellow darken-2" />
                      </template>
                      <div v-else class="red--text">
                        Total Imported: <b>{{ importCount.totalcount }}</b
                        >, Duplicate Count: <b>{{ importCount.dupcount }}</b>
                      </div>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <template v-if="!readOnly">
            <v-btn plain :disabled="loading || !valid || saving" @click="save()">Save</v-btn>
            <v-btn plain @click="getCount()" color="primary" :disabled="loading || !valid || saving"
              >Get Count</v-btn
            >
            <v-btn
              plain
              @click="save(true)"
              color="primary"
              :disabled="loading || !valid || saving || companyToImport == 0"
              >Import</v-btn
            >
          </template>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Query from "@/components/database/Query";
import DedupeControl from "@/components/lnp/DBManagement/DedupeControl";

export default {
  components: {
    Query,
    DedupeControl
  },
  props: ["campaignId", "dbItems", "uploadId", "value"],
  computed: {
    companyToImport() {
      let cnt = Number.isInteger(this.count.compcount)
        ? this.count.compcount - this.count.dupcompany
        : 0;
      if (Number.isInteger(this.values.compcount)) cnt = this.values.compcount;
      return cnt;
    }
  },
  data() {
    return {
      readOnly: false,
      importCount: {},
      count: {},
      valid: false,
      clicked: {
        count: false,
        import: false
      },
      loading: false,
      saving: false,
      loadingQuery: false,
      values: {},
      attrs: null
    };
  },
  watch: {
    value: {
      immediate: true,
      handler(v) {
        if (v) this.load();
      }
    }
  },
  methods: {
    onDBSelect(classId) {
      this.loadingQuery = true;
      this.$axios
        .get("/lnp/db/upload/getQuery", {
          params: {
            campaignId: this.campaignId,
            classId: classId
          }
        })
        .then((res) => {
          this.attrs = res.data.items;
          this.values.queryClassId = res.data.queryClassId;
          this.values.md5 = res.data.md5;
          this.$refs.query.init(res.data.query, this.attrs);
        })
        .finally(() => {
          this.loadingQuery = false;
        });
    },
    save(doImport = false) {
      this.saving = true;
      if (doImport) {
        this.clicked.import = true;
        this.scrollToBottom();
      }
      let query = this.$refs.query.getFilters();
      this.$axios({
        method: "post",
        url: "/lnp/db/upload/save",
        data: {
          campaignId: this.campaignId,
          query: query,
          doImport: doImport,
          companyCount: this.companyToImport,
          uploadId: this.uploadId,
          ...this.values
        }
      })
        .then((res) => {
          if (doImport) {
            this.importCount = res.data;
            this.scrollToBottom();
            this.sendNotification("Import has been completed", true);
          } else {
            this.$store.commit("sendAlert", {
              msg: "Import saved successfully",
              color: "success"
            });
          }
          this.$emit("update", true);
        })
        .finally(() => {
          this.saving = false;
        });
    },
    getCount() {
      this.count = {};
      this.saving = true;
      this.clicked.count = true;
      let query = this.$refs.query.getFilters();
      this.scrollToBottom();
      this.$axios({
        method: "post",
        url: "/lnp/db/upload/getQueryCount",
        data: {
          campaignId: this.campaignId,
          query: query,
          ...this.values
        }
      })
        .then((res) => {
          this.sendNotification("Query Count finished", true);
          this.count = res.data.count;
          this.scrollToBottom();
        })
        .finally(() => {
          this.saving = false;
        });
    },
    scrollToBottom() {
      //scroll to bottom
      this.$nextTick(() => {
        document.getElementsByClassName("contentContainer")[0].scrollTop = 9999;
      });
    },
    closeForm() {
      this.$emit("input", false);
    },
    fetch() {
      this.loading = true;
      this.$axios
        .get("/lnp/db/upload/getUpload", {
          params: {
            id: this.uploadId
          }
        })
        .then((res) => {
          this.readOnly = res.data.readonly;
          this.values = res.data.upload;
          this.values.queryClassId = res.data.query.queryClassId;
          this.attrs = res.data.query.items;
          this.$refs.query.init(res.data.query.query, this.attrs);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    load() {
      this.attrs = null;
      this.readOnly = false;
      this.count = this.importCount = this.clicked = {};
      this.values = {
        DedupOptions: ["1000001013,1000001014"]
      };
      if (this.uploadId) {
        this.fetch();
      }
    }
  }
};
</script>
